import React from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";

import { Link } from "react-router-dom";


function GraphCard({ graphName, graphComponent, graphLink, desiredCardWidth, desiredCardHeight, graphLegendComponent }) {
    return (
        <>
            <Card style={{ height: '100%' }} sx={{ mr: 1.5, minWidth: desiredCardWidth ? (desiredCardWidth) : (75), minHeight: desiredCardHeight ? (desiredCardHeight) : (400), width: desiredCardWidth ? (desiredCardWidth) : (400) }}>
               
                <CardContent>
                    <Typography textAlign="left" variant="h6" fontWeight="600" fontSize="1.55em" component="div">

                        {graphName}

                    </Typography>
                   
                  

                    {graphComponent ? (<>{graphComponent}</>) : (<></>)}
                    {graphLegendComponent ? (<div>
                        {graphLegendComponent}
                    </div>) : (<></>)}

                </CardContent>

            </Card>
        </>
    );
}

export default GraphCard;

