import { useState, createContext, useContext, useEffect, useMemo, Children } from "react";
import { useNavigate, useLocation, Outlet, Navigate } from "react-router-dom";

import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest, b2cPolicies } from '../../authConfig';
import { RolesContext } from "../../App";
import { PermissionsContext } from "../../App";
import { SidebarContext } from "../../App";

//checks if use has accses to route
function AuthGuard({ ...props }) {

 
    const [userId, setUserId] = useState(''); //user id (from b2c token)
    const [entity, setEntity] = useState(''); //entity
    //const [role, setRole] = useState(''); //role for the user
    const [products, setProducts] = useState([]); //products include features available to the user

    const { instance, accounts, inProgress } = useMsal();
    const accessControl = instance.accessControl; //access allowed by user's role
    const [isAuthorized, setIsAuthorized] = useState(true);
    const isAuthenticated = useIsAuthenticated();
    const { roles, setRoles } = useContext(RolesContext);
    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { sidebarTitle, setSidebarTitle, sidebarActions, setSidebarActions, sidebarUserRole, setSidebarUserRole, setSidebarAvatarInitials, sidebarAvatarInitials } = useContext(SidebarContext);
    const [tempPerms, setTempPerms] = useState([]);
    const [perms, setPerms] = useState(false);
  

    const onLoad = async () => {
        //const account = accounts[0].idTokenClaims; //get user account claims from b2c id token
        const account = instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn).idTokenClaims;
        setUserId(account.sub); //get user id from b2c token
        console.log(account.sub);
        console.log(account);
        if (accounts.length > 0) { //if account exists authorize user
            console.log(account.sub)
            //retrieve access token from b2c
            instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {
                    console.log(result);
                    //let accessToken = result.accessToken;
                    console.log("result id token - " + result.idToken);
                    console.log("Access URI - " + process.env.REACT_APP_BACK_URL + '/api/Auth/Authorize');
                    //call authorization method
                    fetch(process.env.REACT_APP_BACK_URL + '/api/Auth/Authorize', {//REACT_APP_BACK_URL
                        method: 'GET',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + result?.idToken,
                        },

                    })
                        .then(res => res.json())
                        .then((response) => {
                            console.log(response)
                            setRoles(response?.userRole?.roleId)
                            setSidebarUserRole(response?.userRole?.roleId)
                            setPermissions([]);
                            setSidebarAvatarInitials(account?.given_name?.slice(0, 1) + account?.family_name?.slice(0, 1))
                            console.log("permissions ---- ", permissions)
                            response?.auditIds?.auditIds?.map((audit) => {
                                //setTempPerms(tempPerms => [...tempPerms,
                                //{
                                //    isClient: response?.isClient,
                                //    auditId: audit?.auditId,
                                //    auditName: audit?.auditName
                                //}

                                //])

                                setPermissions(permissions => [...permissions,
                                {
                                    isClient: response?.isClient,
                                    auditId: audit?.auditId,
                                    auditName: audit?.auditName
                                }

                                ])
                            });

                            setPerms(true);

                            //setPermissions([
                            //    {
                            //        isClient: response?.isClient,
                            //        auditId: response?.auditIds?.auditIds[0]?.auditId,
                            //        auditName: response?.auditIds?.auditIds[0]?.auditName

                            //    }
                            //]);

                        }).catch((error) => {
                            console.log(error)
                        })
                            

                }).catch((error) => {
                    console.log(error)
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                });

              

        }


    };
    //TODO FIX RACE CONDITION (TOKEN NOT FOUND)
    useEffect(() => {
        if (accounts.length > 0) {
            onLoad();
        }

    }, [accounts]);

    useEffect(() => {
        setPermissions(tempPerms);
    }, [tempPerms]);

    return (
        //<div>
        //    {isAuthenticated ? (<div> {props.children} </div>) : (<></>) }
        //</div>
        <div> {props.children} </div>
    );
}

export default AuthGuard;