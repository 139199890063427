import React, { useState, useEffect, PureComponent, useContext, useCallback, useRef } from 'react';
import { useParams, useNavigate, Navigate, Link, redirect } from "react-router-dom";
import { Bar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCalendar } from '@nivo/calendar';
import { BsCheckLg, BsXLg, BsExclamationCircleFill } from 'react-icons/bs';


import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ProjectCard from "../components/cards/ProjectCard";
import ProjectTable from "../components/tables/ProjectTable";
import MultiBarChart from "../components/graphs/MultiBarChart";
import Tooltip from "@mui/material/Tooltip";
import GraphCard from "../components/cards/GraphCard";
import PieGraph from "../components/graphs/PieGraph";
import IneligibleReasonBarChart from "../components/graphs/IneligibleReasonBarChart";
import IneligibleExtendedGraphCard from "../components/cards/IneligibleExtendedGraphCard";
import { RolesContext, SidebarContext } from "../App";
import GraphLegendCard from '../components/cards/GraphLegendCard';
import PercentageBarChart from "../components/graphs/PercentageBarChart";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext } from "../App";
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { loginRequest, b2cPolicies } from "../authConfig";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import useFetch from "../hooks/useFetch";
import Badge from '@mui/material/Badge';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 

//project page
function Project({ onChange, children }) {

    const [responseRateData, setResponseRateData] = useState()
    const [employeeStatusData, setEmployeeStatusData] = useState()
    const [dependentStatusData, setDependentStatusData] = useState()
    const [ineligibleStatusData, setIneligibleStatusData] = useState()
    const [incompleteStatusData, setIncompleteStatusData] = useState()
    const {
        setSidebarTitle,
        setSidebarActions,
        sidebarActiveClientID,
        setSidebarActiveClientID
    } = useContext(SidebarContext);


    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);
    const { accounts, instance } = useMsal();
    const { id, auditName } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isTwoLists, setIsTwoLists] = useState(false);
    const [firstIneligibleList, setFirstIneligibleList] = useState([]);
    const [secIneligibleList, setSecIneligibleList] = useState([]);
    const [finalIneligibleList, setFinalIneligibleLst] = useState([]);
    const [hideIneligibleChart, setHideIneligibleChart] = useState(false);
    const [validRoute, setValidRoute] = useState(null);
    const [showIneligibleChart, setShowIneligibleChart] = useState(true);
    const [showIncompleteChart, setShowIncompleteChart] = useState(true);
    const [popAlertToast, setPopAlertToast] = useState(false);
    const fetchDataRef = useRef(false);

    useEffect(() => {

        if (id !== sidebarActiveClientID) {
            setIsLoading(false);
        }

    }, [id, auditName]);

    //useEffect(() => {

        

    //    if (isLoading === false) {
    //        setSidebarActiveClientID(`${id}`)
    //        setSidebarTitle(`${auditName}`)
    //        console.log("setSideBar call - ", auditName)
    //        setPopAlertToast(false)
    //        setIsLoading(true);

    //    }
            
      
     
    //}, [id, auditName]);

    useEffect(() => {

        if (isLoading === false) {
            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)
            setPopAlertToast(false)
            setIsLoading(true);

        }

    }, [isLoading]);

    const { newRoutes, setRoutes } = useContext(RoutesContext);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: ''
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue'
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: false,
            style: ''
        },
    ]);

    const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);

    useEffect(() => {
    
        console.log("valid Route --- ", validRoute)
        if ((roles === "CLIENT") && (validRoute === false)) {

            navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            setIsLoading(false);

        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length <= 1)) {
            setRoutes([
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue'
                },
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length > 1)) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: ''
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue'
                },
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles == "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: ''
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue'
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: false,
                    style: ''
                },
            ])

        }


    }, [id, auditName, roles, validRoute]);

    const [count, setCount] = useState(0);

    useEffect(() => {
        setIneligibleStatusData()
        setFirstIneligibleList([])
        setFinalIneligibleLst([])
        setSecIneligibleList([])
        permissions?.map((audit) => {
            if (audit?.auditId === parseInt(id)) {
                setValidRoute(true)
                setCount(count + 1);
            }

        });
        console.log("count -- ", count)
        console.log("permissions -- ", permissions)
        if ((validRoute === null) && (count === permissions.length)) {
            setValidRoute(false)
        }

    }, []);
    

    const [extendIneligibleGraphContainer, setExtendIneligibleGraphContainer] = useState(false);


    useEffect(() => {

        console.log("isLoading called - ", isLoading);

        if (isLoading === false) {


            //const fetchResponseRateGraphData = async () => { ///

            //    //console.log(account);
            //    if (accounts.length > 0) {
            //        const account = accounts[0].idTokenClaims;
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {
            //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetResponseRate', {
            //                        method: 'POST',
            //                        mode: 'cors',
            //                        headers: {
            //                            'Content-Type': 'application/json',
            //                            'Authorization': 'Bearer ' + result.idToken,
            //                        },
            //                        body: JSON.stringify({ "auditId": parseInt(id) })
            //                    })
            //                        .then(res => res.json())
            //                        .then((response) => {
            //                            /*  console.log("PROJECT Result Test: ", response)*/
            //                            setResponseRateData(response);

            //                        }).catch((error) => {
            //                            console.log(error)
            //                        })
            //                }

            //                fetchData();

            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }

            //    //setActiveAudits(data.activeAudits);
            //}

            //const fetchEmployeeStatusGraphData = async () => { ///
            //    if (accounts.length > 0) {
            //        const account = accounts[0].idTokenClaims;
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {
            //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetEmployeeStatus', {
            //                        method: 'POST',
            //                        mode: 'cors',
            //                        headers: {
            //                            'Content-Type': 'application/json',
            //                            'Authorization': 'Bearer ' + result.idToken,
            //                        },
            //                        body: JSON.stringify({ "auditId": parseInt(id) })
            //                    })
            //                        .then(res => res.json())
            //                        .then((result) => {
            //                            /*  console.log("PROJECT Employee Status Data Result: ", result)*/
            //                            setEmployeeStatusData(result);

            //                        }).catch((error) => {
            //                            console.log(error)
            //                        })

            //                }

            //                fetchData();

            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }
            //}

            //const fetchDependentStatusGraphData = async () => { ///

            //    if (accounts.length > 0) {
            //        const account = accounts[0].idTokenClaims;
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {
            //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetDependentStatus', {
            //                        method: 'POST',
            //                        mode: 'cors',
            //                        headers: {
            //                            'Content-Type': 'application/json',
            //                            'Authorization': 'Bearer ' + result.idToken,
            //                        },
            //                        body: JSON.stringify({ "auditId": parseInt(id) })
            //                    })
            //                        .then(res => res.json())
            //                        .then((result) => {
            //                            /*  console.log("PROJECT Dependent Status Data Result: ", result)*/
            //                            setDependentStatusData(result);

            //                        }).catch((error) => {
            //                            console.log(error)
            //                        })

            //                }

            //                fetchData();

            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }
            //}

            //const fetchAlertBadgeData = async () => { ///


            //    if (accounts.length > 0) {
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {
            //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAlertReportData', {
            //                        method: 'POST',
            //                        mode: 'cors',
            //                        headers: {
            //                            'Content-Type': 'application/json',
            //                            'Authorization': 'Bearer ' + result.idToken,
            //                        },
            //                        body: JSON.stringify({ "auditId": id })
            //                    })
            //                        .then(res => res.json())
            //                        .then((result) => {

            //                            console.log("alert report result - ", result);

            //                            if (result?.length > 0) {
            //                                setAlertNoteCount(result?.length);
            //                                setReportBadge(false);
            //                                setPopAlertToast(true);
            //                            }

            //                            if (result?.length === 0) {
            //                                setAlertNoteCount(0);
            //                                setReportBadge(true);
            //                            }

                          

            //                        }).catch((error) => {
            //                            console.log(error)
            //                        })
            //                }
            //                fetchData();
            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }

            //}

            //const fetchIneligibleGraphData = async () => {///
            

            //    if (accounts.length > 0) {
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {
            //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIneligibleStatus', {
            //                        method: 'POST',
            //                        mode: 'cors',
            //                        headers: {
            //                            'Content-Type': 'application/json',
            //                            'Authorization': 'Bearer ' + result.idToken,
            //                        },
            //                        body: JSON.stringify({ "auditId": id })
            //                    })
            //                        .then(res => res.json())
            //                        .then((result) => {

            //                            console.log("ineligible result - ", result);
            //                            setFirstIneligibleList([])
            //                            setFinalIneligibleLst([])
            //                            setSecIneligibleList([])
            //                            setIneligibleStatusData(result);

            //                            if (result?.notEligible > 0) {
            //                                console.log("not eligible + 1");
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Relationship Not Eligible",
            //                                    metricValue: result?.notEligible,
            //                                    metricPercentage: Number(result?.notEligiblePercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.exceedsAgeRequirements > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Exceeds Age Requirements",
            //                                    metricValue: result?.exceedsAgeRequirements,
            //                                    metricPercentage: Number(result?.exceedsAgeRequirementsPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.relationshipNotSupportedByDoc > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Relationship Not Supported By Docs",
            //                                    metricValue: result?.relationshipNotSupportedByDoc,
            //                                    metricPercentage: Number(result?.relationshipNotSupportedByDocPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.divorced > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Divorced",
            //                                    metricValue: result?.divorced,
            //                                    metricPercentage: Number(result?.divorcedPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.legalSeparation > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Legal Separation",
            //                                    metricValue: result?.legalSeparation,
            //                                    metricPercentage: Number(result?.legalSeparationPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.notFullTimeStudent > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Not Full-Time Student",
            //                                    metricValue: result?.notFullTimeStudent,
            //                                    metricPercentage: Number(result?.notFullTimeStudentPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.military > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Military",
            //                                    metricValue: result?.military,
            //                                    metricPercentage: Number(result?.militaryPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.employeeRequest > 0) {
            //                                console.log("employee request + 1");
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Employee Request",
            //                                    metricValue: result?.employeeRequest,
            //                                    metricPercentage: Number(result?.employeeRequestPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.qualifiedEvent > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Qualified Event",
            //                                    metricValue: result?.qualifiedEvent,
            //                                    metricPercentage: Number(result?.qualifiedEventPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.eligibleForOtherCoverage > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Eligible for Other Coverage",
            //                                    metricValue: result?.eligibleForOtherCoverage,
            //                                    metricPercentage: Number(result?.eligibleForOtherCoveragePercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.ineligibleForDentalEligibleForMedVision > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Dental, Eligible: Med, Vision",
            //                                    metricValue: result?.ineligibleForDentalEligibleForMedVision,
            //                                    metricPercentage: Number(result?.ineligibleForDentalEligibleForMedVisionPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.ineligibleForMedicalDentalEligibleVision > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Dental, Med, Eligible: Vision",
            //                                    metricValue: result?.ineligibleForMedicalDentalEligibleVision,
            //                                    metricPercentage: Number(result?.ineligibleForMedicalDentalEligibleVisionPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.ineligibleForMedVisionEligibleForDental > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Vision, Med, Eligible: Dental",
            //                                    metricValue: result?.ineligibleForMedVisionEligibleForDental,
            //                                    metricPercentage: Number(result?.ineligibleForMedVisionEligibleForDentalPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.ineligibleForMedEligibleDentalVision > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Med, Eligible: Dental, Vision",
            //                                    metricValue: result?.ineligibleForMedEligibleDentalVision,
            //                                    metricPercentage: Number(result?.ineligibleForMedEligibleDentalVisionPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.ineligibleForDentalVisionEligibleForMed > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Vision, Dental, Eligible: Med",
            //                                    metricValue: result?.ineligibleForDentalVisionEligibleForMed,
            //                                    metricPercentage: Number(result?.ineligibleForDentalVisionEligibleForMedPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result?.ineligibleForVisionEligibleMedicalDental > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Vision, Eligible: Med, Dental",
            //                                    metricValue: result?.ineligibleForVisionEligibleMedicalDental,
            //                                    metricPercentage: Number(result?.ineligibleForVisionEligibleMedicalDentalPercent * 100).toFixed(2),
            //                                }])
            //                            }
            //                            if (result?.total === 0) {
            //                                setShowIneligibleChart(false);
            //                            }

            //                        }).catch((error) => {
            //                            console.log(error)
            //                        })
            //                }
            //                fetchData();
            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }

            //}

            //const fetchIncompleteGraphData = async () => {///


            //    if (accounts.length > 0) {
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {
            //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIncompleteStatus', {
            //                        method: 'POST',
            //                        mode: 'cors',
            //                        headers: {
            //                            'Content-Type': 'application/json',
            //                            'Authorization': 'Bearer ' + result.idToken,
            //                        },
            //                        body: JSON.stringify({ "auditId": id })
            //                    })
            //                        .then(res => res.json())
            //                        .then((result) => {

            //                            console.log("incomplete result - ", result);
            //                            setIncompleteStatusData(result);
            //                            if (result?.total === 0) {
            //                                setShowIncompleteChart(false);
            //                            }



            //                        }).catch((error) => {
            //                            console.log(error)
            //                        })
            //                }
            //                fetchData();
            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }

            //}

   

            //if (!fetchDataRef.current) {
            //    fetchDataRef.current = true;
            //    fetchResponseRateGraphData()
            //    fetchEmployeeStatusGraphData()
            //    fetchDependentStatusGraphData()
            //    fetchAlertBadgeData()
            //    fetchIneligibleGraphData()
            //    fetchIncompleteGraphData()

            //}

            //if (fetchDataRef.current) {
            //    return;
            //}
        
            const fetchInPromise = async () => {
                if (accounts.length > 0) {
                    await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                        .then(result => {
                            const fetchData = () => {
                                Promise.all([
                                    fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetResponseRate', {
                                        method: 'POST',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                        body: JSON.stringify({ "auditId": parseInt(id) })
                                    }),
                                    fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetEmployeeStatus', {
                                        method: 'POST',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                        body: JSON.stringify({ "auditId": parseInt(id) })
                                    }),
                                    fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetDependentStatus', {
                                        method: 'POST',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                        body: JSON.stringify({ "auditId": parseInt(id) })
                                    }),
                                    fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAlertReportData', {
                                        method: 'POST',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                        body: JSON.stringify({ "auditId": id })
                                    }),
                                    fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIneligibleStatus', {
                                        method: 'POST',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                        body: JSON.stringify({ "auditId": id })
                                    }),
                                    fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIncompleteStatus', {
                                        method: 'POST',
                                        mode: 'cors',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + result.idToken,
                                        },
                                        body: JSON.stringify({ "auditId": id })
                                    })

                                ])
                                    .then(results => Promise.all(results.map(res => res.json())))
                                        .then((result) => {
                                            console.log("PROJECT FETCH RESULT - ", result)
                                            setResponseRateData(result[0]);
                                            setEmployeeStatusData(result[1]);
                                            setDependentStatusData(result[2]);
                                            if (result[3]?.length > 0) {
                                                setAlertNoteCount(result[3]?.length);
                                                setReportBadge(false);
                                                setPopAlertToast(true);
                                            }

                                            if (result[3]?.length === 0) {
                                                setAlertNoteCount(0);
                                                setReportBadge(true);
                                            }
                                            setFirstIneligibleList([])
                                            setFinalIneligibleLst([])
                                            setSecIneligibleList([])
                                            setIneligibleStatusData(result[4]);

                                            if (result[4]?.notEligible > 0) {
                                                console.log("not eligible + 1");
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Relationship Not Eligible",
                                                    metricValue: result[4]?.notEligible,
                                                    metricPercentage: Number(result[4]?.notEligiblePercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.exceedsAgeRequirements > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Exceeds Age Requirements",
                                                    metricValue: result[4]?.exceedsAgeRequirements,
                                                    metricPercentage: Number(result[4]?.exceedsAgeRequirementsPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.relationshipNotSupportedByDoc > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Relationship Not Supported By Docs",
                                                    metricValue: result[4]?.relationshipNotSupportedByDoc,
                                                    metricPercentage: Number(result[4]?.relationshipNotSupportedByDocPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.divorced > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Divorced",
                                                    metricValue: result[4]?.divorced,
                                                    metricPercentage: Number(result[4]?.divorcedPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.legalSeparation > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Legal Separation",
                                                    metricValue: result[4]?.legalSeparation,
                                                    metricPercentage: Number(result[4]?.legalSeparationPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.notFullTimeStudent > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Not Full-Time Student",
                                                    metricValue: result[4]?.notFullTimeStudent,
                                                    metricPercentage: Number(result[4]?.notFullTimeStudentPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.military > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Military",
                                                    metricValue: result[4]?.military,
                                                    metricPercentage: Number(result[4]?.militaryPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.employeeRequest > 0) {
                                                console.log("employee request + 1");
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Employee Request",
                                                    metricValue: result[4]?.employeeRequest,
                                                    metricPercentage: Number(result[4]?.employeeRequestPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.qualifiedEvent > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Qualified Event",
                                                    metricValue: result[4]?.qualifiedEvent,
                                                    metricPercentage: Number(result[4]?.qualifiedEventPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.eligibleForOtherCoverage > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Eligible for Other Coverage",
                                                    metricValue: result[4]?.eligibleForOtherCoverage,
                                                    metricPercentage: Number(result[4]?.eligibleForOtherCoveragePercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.ineligibleForDentalEligibleForMedVision > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Ineligible: Dental, Eligible: Med, Vision",
                                                    metricValue: result[4]?.ineligibleForDentalEligibleForMedVision,
                                                    metricPercentage: Number(result[4]?.ineligibleForDentalEligibleForMedVisionPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.ineligibleForMedicalDentalEligibleVision > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Ineligible: Dental, Med, Eligible: Vision",
                                                    metricValue: result[4]?.ineligibleForMedicalDentalEligibleVision,
                                                    metricPercentage: Number(result[4]?.ineligibleForMedicalDentalEligibleVisionPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.ineligibleForMedVisionEligibleForDental > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Ineligible: Vision, Med, Eligible: Dental",
                                                    metricValue: result[4]?.ineligibleForMedVisionEligibleForDental,
                                                    metricPercentage: Number(result[4]?.ineligibleForMedVisionEligibleForDentalPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.ineligibleForMedEligibleDentalVision > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Ineligible: Med, Eligible: Dental, Vision",
                                                    metricValue: result[4]?.ineligibleForMedEligibleDentalVision,
                                                    metricPercentage: Number(result[4]?.ineligibleForMedEligibleDentalVisionPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.ineligibleForDentalVisionEligibleForMed > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Ineligible: Vision, Dental, Eligible: Med",
                                                    metricValue: result[4]?.ineligibleForDentalVisionEligibleForMed,
                                                    metricPercentage: Number(result[4]?.ineligibleForDentalVisionEligibleForMedPercent * 100).toFixed(2),
                                                }])
                                            }

                                            if (result[4]?.ineligibleForVisionEligibleMedicalDental > 0) {
                                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                    metricTitle: "Ineligible: Vision, Eligible: Med, Dental",
                                                    metricValue: result[4]?.ineligibleForVisionEligibleMedicalDental,
                                                    metricPercentage: Number(result[4]?.ineligibleForVisionEligibleMedicalDentalPercent * 100).toFixed(2),
                                                }])
                                            }
                                            if (result[4]?.total === 0) {
                                                setShowIneligibleChart(false);
                                            }

                                            setIncompleteStatusData(result[5]);
                                            if (result[5]?.total === 0) {
                                                setShowIncompleteChart(false);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log('promise error -', error); //promise failed
                                            //abort fetch request here
                                        });

                                
                            }
                            fetchData();
                        }).catch((error) => {
                            console.log(error) //token expired/is not valid => user is redirected to login page
                            if (error instanceof InteractionRequiredAuthError) {
                                instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                            }
                        });
                }
            }

            fetchInPromise();

           
   
        }
       

    }, [sidebarActiveClientID, accounts, id, instance, isLoading]);

    useEffect(() => {
        if (toast.isActive("newAlertsNotification")) {
            toast.update("newAlertsNotification", {
                type: toast.TYPE.ERROR,
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                style: { background: '#d32f2f' },
                
            })
        }else if ((popAlertToast === true) && (!toast.isActive("newAlertsNotification"))){
            toast.error("Alerts are available to export", {
                position: "bottom-center",
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                style: { background: '#d32f2f' },
                toastId: "newAlertsNotification"
               
            });
        }

    }, [popAlertToast]);

    useEffect(() => {
        setSecIneligibleList([])
        setFirstIneligibleList([])
        if (finalIneligibleList.length > 8) {
            setExtendIneligibleGraphContainer(true);
            for (let i = 0; i < finalIneligibleList.length; i++) {
                if (i <= 7) {
                    setFirstIneligibleList(firstIneligibleList => [...firstIneligibleList, finalIneligibleList[i]])
                }

                if (i > 7) {
                    setSecIneligibleList(secIneligibleList => [...secIneligibleList, finalIneligibleList[i]])
                }

            }
        }

        if (finalIneligibleList.length === 0) {
            setHideIneligibleChart(true);
        }


    }, [finalIneligibleList]);



    /* RESPONSE RATE GRAPH DATA */
    let RRGrandTotal = responseRateData?.responseRateGrandTotal;
    let RRNoResponsePercent = Number(responseRateData?.responseRateNoResponsePercent * 100).toFixed(2);
    let RRNoResponseTotal = responseRateData?.responseRateNoResponseTotal;
    let RRRespondedPercent = Number(responseRateData?.responseRateRespondedPercent * 100).toFixed(2);
    let RRRespondedTotal = responseRateData?.responseRateRespondedTotal;

    /* EMPLOYEE STATUS GRAPH DATA*/

    let ESGrandTotal = employeeStatusData?.employeeStatusGrandTotal;
    let ESCompleteTotal = employeeStatusData?.employeeStatusCompleteTotal;
    let ESIncompleteTotal = employeeStatusData?.employeeStatusIncompleteTotal;
    let ESNoResponseTotal = employeeStatusData?.employeeStatusNoResponseTotal;
    let ESCompletePercent = Number(employeeStatusData?.employeeStatusCompletePercent * 100).toFixed(2);
    let ESIncompletePercent = Number(employeeStatusData?.employeeStatusIncompletePercent * 100).toFixed(2);
    let ESNoResponsePercent = Number(employeeStatusData?.employeeStatusNoResponsePercent * 100).toFixed(2);

    /* DEPENDENT STATUS GRAPH DATA*/

    let DSGrandTotal = dependentStatusData?.dependentStatusGrandTotal;
    let DSPassedTotal = dependentStatusData?.dependentStatusPassedTotal;
    let DSSuspendTotal = dependentStatusData?.dependentStatusSuspendTotal;
    let DSIneligibleTotal = dependentStatusData?.dependentStatusIneligibleTotal;
    let DSReviewTotal = dependentStatusData?.dependentStatusReviewTotal;
    let DSIncompleteTotal = dependentStatusData?.dependentStatusIncompleteTotal;
    let DSNoResponseTotal = dependentStatusData?.dependentStatusNoResponseTotal;


    let DSPassedPercent = Number(dependentStatusData?.dependentStatusPassedPercent * 100).toFixed(2);
    let DsSuspendPercent = Number(dependentStatusData?.dependentStatusSuspendPercent * 100).toFixed(2);
    let DSIneligiblePercent = Number(dependentStatusData?.dependentStatusIneligiblePercent * 100).toFixed(2);
    let DSReviewPercent = Number(dependentStatusData?.dependentStatusReviewPercent * 100).toFixed(2);
    let DSIncompletePercent = Number(dependentStatusData?.dependentStatusIncompletePercent * 100).toFixed(2);
    let DSNoResponsePercent = Number(dependentStatusData?.dependentStatusNoResponsePercent * 100).toFixed(2);

    /* Incomplete Status Graph data */
    let ISInvalidDocReason = incompleteStatusData?.invalidDocumentReason;
    let ISInvalidDocPercent = Number(incompleteStatusData?.invalidDocPercent * 100).toFixed(2);
    let ISMissingDocReason = incompleteStatusData?.missingDocReason;
    let ISMissingDocPercent = Number(incompleteStatusData?.missingDocPercent * 100).toFixed(2);
    let ISDocNotLegibleReason = incompleteStatusData?.docNotLegibleReason;
    let ISDocNotLegiblePercent = Number(incompleteStatusData?.docNotLegiblePercent * 100).toFixed(2);
    let ISGrandTotal = incompleteStatusData?.total;

  


   



    const [reports, setReports] = useState([]);
    const initStatusTally = [{ id: 'No Response', value: 0, color: "hsl(85, 70%, 50%)" }, { id: 'Incomplete', value: 4000, color: "hsl(287, 70%, 50%)" }, { id: 'Complete', value: 100, color: "hsl(248, 70%, 50%)" }, { id: 'Ineligible', value: 500 }, { id: 'Suspend', value: 0, color: "hsl(142, 70%, 50%)" }]
    const [statusTally, setStatusTally] = useState(initStatusTally)
    const initEmailTally = [{ id: 'Yes', value: 928, color: "hsl(248, 70%, 50%)" }, { id: 'No', value: 207, color: "hsl(85, 70%, 50%)" }]
    const [emailTally, setEmailTally] = useState(initEmailTally)
    const initTotalTally = [{ type: "Employee", value: 0 }, { type: "Dependent", value: 0 }, { type: "Combined", value: 0 }]
    const [totalTally, setTotalTally] = useState(initTotalTally)
    const calendarDates = [{ value: 22, day: "2023-01-17" }, { value: 49, day: "2023-01-23" }, { value: 105, day: "2023-02-13" }, { value: 53, day: "2023-02-15" }, { value: 2, day: "2023-02-17" }, { value: 86, day: "2023-02-21" }];



 

    const [dependentReportData, setDependentReportData] = useState([]);
    function fetchCSVFormattedDataAndPackageForDownload() {

        const fetchDependentData = async () => {

            if (accounts.length > 0) {

                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetDependentStatusData', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify({ "auditId": id })
                            })
                                .then(res => res.json())
                                .then((result) => {

                                   

                                    const options = {
                                        filename: `DependentStatusReport-${auditName}`,
                                        fieldSeparator: ',',
                                        quoteStrings: '"',
                                        decimalSeparator: '.',
                                        showLabels: true,
                                        showTitle: false,
                                        useTextFile: false,
                                        useBom: true,
                                        useKeysAsHeaders: true,
                                        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                                    };

                                    const csvExporter = new ExportToCsv(options);
                                   
                                    csvExporter.generateCsv(result);
                                }).catch((error) => {
                                    console.log(error)
                                })
                        }

                        fetchData();

                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                });

            }
            

        }

        const fetchEmployeeData = async () => {
            if (accounts.length > 0) {

                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetEmployeeStatusData', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,

                                },
                                body: JSON.stringify({ "auditId": id })
                            })
                                .then(res => res.json())
                                .then((result) => {

                                   

                                    const options2 = {
                                        filename: `FamilyStatusReport-${auditName}`,
                                        fieldSeparator: ',',
                                        quoteStrings: '"',
                                        decimalSeparator: '.',
                                        showLabels: true,
                                        showTitle: false,
                                        useTextFile: false,
                                        useBom: true,
                                        useKeysAsHeaders: true,
                                        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                                    };

                                    const csvExporter2 = new ExportToCsv(options2);
                                    console.log(result);
                                    csvExporter2.generateCsv(result);
                                }).catch((error) => {
                                    console.log(error)
                                })
                        }
                        fetchData();
                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                });
            }
        }

        const fetchAlertData = async () => {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAlertReportData', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify({ "auditId": id })
                            })
                                .then(res => res.json())
                                .then((result) => {

                                    console.log("alert report result - ", result);

                                    const options3 = {
                                        filename: `AlertReport-${auditName}`,
                                        fieldSeparator: ',',
                                        quoteStrings: '"',
                                        decimalSeparator: '.',
                                        showLabels: true,
                                        showTitle: false,
                                        useTextFile: false,
                                        useBom: true,
                                        useKeysAsHeaders: true,
                                        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                                    };



                                    const csvExporter3 = new ExportToCsv(options3);



                                    csvExporter3.generateCsv(result);
                                }).catch((error) => {
                                    console.log(error)
                                })
                        }
                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }

        }


        const fetchIneligibleData = async () => {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIneligibleReportData', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify({ "auditId": id })
                            })
                                .then(res => res.json())
                                .then((result) => {
                                    console.log("ineligible results -- ", result);
                             
                                    const options4 = {
                                        filename: `IneligibleReport-${auditName}`,
                                        fieldSeparator: ',',
                                        quoteStrings: '"',
                                        decimalSeparator: '.',
                                        showLabels: true,
                                        showTitle: false,
                                        useTextFile: false,
                                        useBom: true,
                                        useKeysAsHeaders: true,
                                        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                                    };
                                    const csvExporter4 = new ExportToCsv(options4);
                                    csvExporter4.generateCsv(result);
                                }).catch((error) => {
                                    console.log(error)
                                })
                        }
                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }

        }
        const fetchIncompleteData = async () => {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIncompleteReportData', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify({ "auditId": id })
                            })
                                .then(res => res.json())
                                .then((result) => {
                                    console.log("incomplete results -- ", result);

                                    const options5 = {
                                        filename: `IncompleteReport-${auditName}`,
                                        fieldSeparator: ',',
                                        quoteStrings: '"',
                                        decimalSeparator: '.',
                                        showLabels: true,
                                        showTitle: false,
                                        useTextFile: false,
                                        useBom: true,
                                        useKeysAsHeaders: true,
                                        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                                    };
                                    const csvExporter5 = new ExportToCsv(options5);
                                    csvExporter5.generateCsv(result);
                                }).catch((error) => {
                                    console.log(error)
                                })
                        }
                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }

        }

        fetchEmployeeData();
        fetchDependentData();
        fetchAlertData();
        fetchIneligibleData();
        fetchIncompleteData();
      

      
    }

    return (
        <>
            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', xl: 'block' } }} >
                
                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                    <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                        <div className="row-left">
               
                            {responseRateData != undefined && employeeStatusData != undefined && dependentStatusData != undefined ? (<>
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>Dashboard</Typography>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={126} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                        <div className="row-right">
                           
                            {responseRateData != undefined && employeeStatusData != undefined && dependentStatusData != undefined ? (<>
                                <Tooltip title="Export Graph Data as a CSV">
                                    <Badge color="error" badgeContent={alertNoteCount} invisible={reportBadge}>
                                    <Button variant="contained"
                                        disableElevation sx={{
                                            bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                            ':hover': {
                                                bgcolor: '#dddddd', // theme.palette.primary.main
                                                color: '#ffffff',
                                                boxShadow: 0,
                                            },
                                        }} onClick={() => fetchCSVFormattedDataAndPackageForDownload()}
                                        style={{ textTransform: 'none' }}
                                    >
                                        <Typography fontWeight="600" fontSize="18px" >Export</Typography>
                                    </Button>
                                    </Badge>
                                </Tooltip>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                    </div>
                </Container >
         

                {/* Project Cards - DESKTOP  */}
                <Container sx={{ ml: 0, }}>
                    <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                       

                    </Grid>
                </Container>

                <Container sx={{ ml: 0 }}>
                    <Grid container spacing={3} sx={{ mt: 0.5, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        <Grid item xs="auto" >

                            {responseRateData != undefined ? (
                                <GraphCard
                                    graphName={"Response Rate"}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphComponent={





                                        <PieGraph
                                            graphValueA={RRRespondedTotal}
                                            graphValueB={RRNoResponseTotal}
                                            graphValueC={0}
                                            graphLabelValueA={"Responded"}
                                            graphLabelValueB={"No Response"}
                                            metricSubTitle={'Response Rate'}
                                            alternateCenterMetricPercent={RRRespondedPercent}
                                            graphTitle={`Response Rate Graph - ${RRRespondedPercent} Total Percent Responded`}
                                        />





                                    }
                                    graphLegendComponent={
                                        responseRateData != undefined ? (
                                            <GraphLegendCard
                                                graphLegendData={
                                                    [
                                                        {
                                                            metricTitle: "Responded",
                                                            metricValue: RRRespondedTotal,
                                                            metricPercentage: RRRespondedPercent,
                                                        },
                                                        {
                                                            metricTitle: "No Response",
                                                            metricValue: RRNoResponseTotal,
                                                            metricPercentage: RRNoResponsePercent,
                                                        },
                                                    ]
                                                }
                                            />) : (<></>)
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={

                                            <></>
                                        }
                                    />
                                </>)}

                        </Grid>
                        <Grid item xs="auto" >
                            {employeeStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Family Status"}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphComponent={

                                        <PieGraph
                                            graphValueA={ESCompleteTotal}
                                            graphValueB={ESNoResponseTotal}
                                            graphValueC={ESIncompleteTotal}
                                            graphLabelValueA={"Complete"}
                                            graphLabelValueB={"No Response"}
                                            graphLabelValueC={"Incomplete"}
                                            metricSubTitle={'Families'}
                                            graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
                                        />
                                    }
                                    graphLegendComponent={

                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Complete",
                                                        metricValue: ESCompleteTotal,
                                                        metricPercentage: ESCompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: ESNoResponseTotal,
                                                        metricPercentage: ESNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: ESIncompleteTotal,
                                                        metricPercentage: ESIncompletePercent,
                                                    },
                                                ]
                                            }
                                        />
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />

                                </>)}
                           
                        </Grid>

                        <Grid item xs="auto">
                            {dependentStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Dependent Status"}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphLegendComponent={

                                        <PercentageBarChart
                                            percentageBarChartData={
                                                [
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: DSNoResponseTotal,
                                                        metricPercentage: DSNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: DSIncompleteTotal,
                                                        metricPercentage: DSIncompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "Review",
                                                        metricValue: DSReviewTotal,
                                                        metricPercentage: DSReviewPercent,
                                                    },
                                                    {
                                                        metricTitle: "Passed",
                                                        metricValue: DSPassedTotal,
                                                        metricPercentage: DSPassedPercent,
                                                    },
                                                    {
                                                        metricTitle: "Suspend",
                                                        metricValue: DSSuspendTotal,
                                                        metricPercentage: DsSuspendPercent,
                                                    },
                                                    {
                                                        metricTitle: "Ineligible",
                                                        metricValue: DSIneligibleTotal,
                                                        metricPercentage: DSIneligiblePercent,
                                                    },

                                                ]
                                            }
                                        />}
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />
                                </>)}
                          
                        </Grid>
                        <>
                        {showIneligibleChart ? (
                        <>
                        {ineligibleStatusData != undefined ? (<>
                            {extendIneligibleGraphContainer ? (
                                <Grid item xs={7.7}>
                                        <IneligibleExtendedGraphCard
                                            graphName={"Ineligible Reason"}
                                            desiredCardWidth={715}
                                            desiredCardHeight={400}
                                            graphLegendComponent={
                                                <Grid container columnSpacing={{ xs: 2 }} justifyContent="center">
                                                    <Grid item sx={2}>
                                                        <IneligibleReasonBarChart
                                                            percentageBarChartData={
                                                                firstIneligibleList
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item sx={2}>
                                                        <IneligibleReasonBarChart
                                                            percentageBarChartData={
                                                                secIneligibleList
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                            }
                                        />
                                </Grid> 
                            ) : (
                                    <Grid item xs="auto">
                                            <GraphCard
                                                graphName={"Ineligible Reason"}
                                                desiredCardWidth={340}
                                                desiredCardHeight={400}
                                                graphLegendComponent={

                                                    <IneligibleReasonBarChart
                                                        percentageBarChartData={ //needs to be dynamic list

                                                            finalIneligibleList


                                                        }
                                                    />}
                                            />
                                    </Grid>
                            
                            
                            )}
                        </>) : (<>
                        <Grid item xs="auto">
                            <GraphCard
                                graphName={""}
                                desiredCardWidth={340}
                                desiredCardHeight={400}
                                graphComponent={

                                <div className="row-no-left-margin">
                                    <div className="center">
                                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                            <Skeleton height={360} width={308} />
                                        </SkeletonTheme>
                                    </div>
                                </div>

                                }
                                graphLegendComponent={
                                 <></>
                                }
                            />               
                        </Grid>
                        </>)}
                        </>
                        ) : (<></>)}
                        </>
                        <>

                        {showIncompleteChart ? (
                            <Grid item xs="auto" >
                                {incompleteStatusData != undefined ? (
                                    <GraphCard
                                        graphName={"Incomplete Reason"}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <PieGraph
                                                graphValueA={ISMissingDocReason}
                                                graphValueB={ISInvalidDocReason}
                                                graphValueC={ISDocNotLegibleReason}
                                                graphLabelValueA={"Missing Document(s)"}
                                                graphLabelValueB={"Invalid Documents"}
                                                graphLabelValueC={"Document Not Legible"}
                                                metricSubTitle={"Incomplete"}
                                                graphTitle={"Incomplete Status Graph"}
                                            />
                                        }
                                        graphLegendComponent={

                                            <GraphLegendCard
                                                graphLegendData={
                                                    [
                                                        {
                                                            metricTitle: "Missing Document(s)",
                                                            metricValue: ISMissingDocReason,
                                                            metricPercentage: ISMissingDocPercent
                                                        },
                                                        {
                                                            metricTitle: "Invalid Documents",
                                                            metricValue: ISInvalidDocReason,
                                                            metricPercentage: ISInvalidDocPercent
                                                        },
                                                        {
                                                            metricTitle: "Document Not Legible",
                                                            metricValue: ISDocNotLegibleReason,
                                                            metricPercentage: ISDocNotLegiblePercent
                                                        },
                                                    ]
                                                }
                                            />
                                        }
                                    />) : (<>
                                        <GraphCard
                                            graphName={""}
                                            desiredCardWidth={340}
                                            desiredCardHeight={400}
                                            graphComponent={

                                                <div className="row-no-left-margin">
                                                    <div className="center">
                                                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                            <Skeleton height={360} width={308} />
                                                        </SkeletonTheme>
                                                    </div>
                                                </div>

                                            }
                                            graphLegendComponent={
                                                <></>
                                            }
                                        />

                                    </>)}

                            </Grid>
                        ) : (<></>)}
                        </>
                      
                    </Grid>
                    <Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                      
                    </Grid>
                </Container>
                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>
            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/* //MOBILE LAYOUT/COMPONENTS*/}

                {/*  MOBILE PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                        <div className="row-left">
                          
                            {responseRateData != undefined && employeeStatusData != undefined && dependentStatusData != undefined ? (<>
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> Dashboard</Typography>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={126} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                        <div className="row-right">
                           
                            {responseRateData != undefined && employeeStatusData != undefined && dependentStatusData != undefined ? (<>
                                <Tooltip title="Export Graph Data as a CSV">
                                    <Badge badgeContent={alertNoteCount} color="error" invisible={reportBadge}>
                                    <Button variant="contained"
                                        disableElevation sx={{
                                            bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                            ':hover': {
                                                bgcolor: '#dddddd', // theme.palette.primary.main
                                                color: '#ffffff',
                                                boxShadow: 0,
                                            },
                                        }}
                                        style={{ textTransform: 'none' }}
                                        onClick={() => fetchCSVFormattedDataAndPackageForDownload()} >
                                        <Typography fontWeight="600" fontSize="18px" >Export</Typography>
                                        </Button>
                                        </Badge>
                                </Tooltip>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                    </div>
                </Container>

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {/* Pinned Claims Audits - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 5, mr: 1, display: { xs: 'block', md: 'none' } }}>
                       
                    </Grid>
                </Container>

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {/* Pinned Claims Audits - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 2, mr: 1, display: { xs: 'block', md: 'none' } }}>
                        <Grid item xs={12} sx={{ pl:0 }}>
                            {responseRateData != undefined ? (
                                <GraphCard
                                    graphName={"Response Rate"}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}

                                    graphComponent={
                                        <div className="row-no-margin">
                                            <div className="center">
                                                <PieGraph
                                                    mobileGraph={true}
                                                    graphValueA={RRRespondedTotal}
                                                    graphValueB={RRNoResponseTotal}
                                                    graphValueC={0}
                                                    graphLabelValueA={"Responded"}
                                                    graphLabelValueB={"No Response"}
                                                    metricSubTitle={'Response Rate'}
                                                    alternateCenterMetricPercent={RRRespondedPercent}
                                                    graphTitle={`Response Rate Graph - ${RRRespondedPercent} Total Percent Responded`}
                                                />
                                            </div>
                                        </div>

                                    }
                                    graphLegendComponent={

                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Responded",
                                                        metricValue: RRRespondedTotal,
                                                        metricPercentage: RRRespondedPercent,
                                                    },
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: RRNoResponseTotal,
                                                        metricPercentage: RRNoResponsePercent,
                                                    },
                                                   
                                                ]
                                            }
                                        />
                                    }
                                />) : (<>

                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphComponent={
                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={

                                            <></>
                                        }
                                    />



                                </>)}
                        </Grid>
                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {employeeStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Family Status"}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphComponent={
                                        <div className="row-no-margin">
                                            <div className="center">
                                                <PieGraph
                                                    mobileGraph={true}
                                                    graphValueA={ESCompleteTotal}
                                                    graphValueB={ESNoResponseTotal}
                                                    graphValueC={ESIncompleteTotal}
                                                    graphLabelValueA={"Complete"}
                                                    graphLabelValueB={"No Response"}
                                                    graphLabelValueC={"Incomplete"}
                                                    metricSubTitle={'Families'}
                                                    graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
                                                />
                                            </div>
                                        </div>
                                    }
                                    graphLegendComponent={
                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Complete",
                                                        metricValue: ESCompleteTotal,
                                                        metricPercentage: ESCompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: ESNoResponseTotal,
                                                        metricPercentage: ESNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: ESIncompleteTotal,
                                                        metricPercentage: ESIncompletePercent,
                                                    },
                                                  
                                                ]
                                            }
                                        />

                                    }
                                />) : (<>

                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />
                                </>)}
                        </Grid>


                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {dependentStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Dependent Status"}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphLegendComponent={
                                        <PercentageBarChart
                                            percentageBarChartData={
                                                [
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: DSNoResponseTotal,
                                                        metricPercentage: DSNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: DSIncompleteTotal,
                                                        metricPercentage: DSIncompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "Review",
                                                        metricValue: DSReviewTotal,
                                                        metricPercentage: DSReviewPercent,
                                                    },
                                                    {
                                                        metricTitle: "Passed",
                                                        metricValue: DSPassedTotal,
                                                        metricPercentage: DSPassedPercent,
                                                    },
                                                    {
                                                        metricTitle: "Suspend",
                                                        metricValue: DSSuspendTotal,
                                                        metricPercentage: DsSuspendPercent,
                                                    },
                                                    {
                                                        metricTitle: "Ineligible",
                                                        metricValue: DSIneligibleTotal,
                                                        metricPercentage: DSIneligiblePercent,
                                                    },

                                                ]
                                            }
                                        />
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphComponent={
                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />
                                </>)} 
                        </Grid>

                        <>
                        {showIneligibleChart ? (
                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {ineligibleStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Ineligible Reason"}
                                    desiredCardWidth={"84VW"}
                                    desiredCardHeight={400}
                                    graphLegendComponent={

                                        <IneligibleReasonBarChart
                                            percentageBarChartData={ //needs to be dynamic list
                                               finalIneligibleList
                                            }
                                        />}
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={"84VW"}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={



                                            <></>
                                        }
                                    />
                                </>)}

                            </Grid>
                        
                            ) : (<></>)}
                        </>
                        <>
                        {showIncompleteChart ? (

                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {incompleteStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Incomplete Reason"}
                                    desiredCardWidth={"84VW"}
                                    desiredCardHeight={400}
                                    graphComponent={

                                        <PieGraph
                                            graphValueA={ISMissingDocReason}
                                            graphValueB={ISInvalidDocReason}
                                            graphValueC={ISDocNotLegibleReason}
                                            graphLabelValueA={"Missing Document(s)"}
                                            graphLabelValueB={"Invalid Documents"}
                                            graphLabelValueC={"Document Not Legible"}
                                            metricSubTitle={"Incomplete"}
                                            graphTitle={"Incomplete Status Graph"}
                                        />
                                    }
                                    graphLegendComponent={

                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Missing Document(s)",
                                                        metricValue: ISMissingDocReason,
                                                        metricPercentage: ISMissingDocPercent
                                                    },
                                                    {
                                                        metricTitle: "Invalid Documents",
                                                        metricValue: ISInvalidDocReason,
                                                        metricPercentage: ISInvalidDocPercent
                                                    },
                                                    {
                                                        metricTitle: "Document Not Legible",
                                                        metricValue: ISDocNotLegibleReason,
                                                        metricPercentage: ISDocNotLegiblePercent
                                                    },
                                                ]
                                            }
                                        />
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={"84VW"}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />

                                </>)}

                        </Grid>
                        ) : (<></>)}
                        </>

                    </Grid>
               
                </Container>
            </Box>
            <ToastContainer
                limit={1}
                rtl={false}

            />
        </>
    )
}

export default Project;
