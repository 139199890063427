import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
/*import { Theme, Button } from 'react-daisyui';*/
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PermissionsContext, RolesContext, SidebarContext } from "../App.js";
//import { loginRequest } from "../authConfig";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import GraphCard from "../components/cards/GraphCard";
import { b2cPolicies, loginRequest, protectedResources } from "../authConfig";

function ReturnNavigation({ isAuthenticated, permsData }) {
    console.log("auditIds return nav - ", permsData?.auditIds?.auditIds[0]?.auditId)
    console.log("auditName return nav - ", permsData?.auditIds?.auditIds[0]?.auditName)
    console.log("isClient? return nav - ", permsData?.isClient)
    console.log("isAuthenticated return nav - ", isAuthenticated)
    console.log("permsData return nav - ", permsData)

    if (isAuthenticated == true && permsData?.isClient == true) {
        /* return <Navigate to={`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`} replace />*/
        return <Navigate to={`/report/${String(permsData?.auditIds?.auditIds[0]?.auditId)}/${permsData?.auditIds?.auditIds[0]?.auditName}`} replace />
    } else if (isAuthenticated == true && permsData?.isClient == false) {
        return <Navigate to="/report" replace />
    } else {
        return <></>
    }

}

function Home() {
    let navigate = useNavigate()
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    //const account =

    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { sidebarActiveUser, setSidebarActiveUser, setSidebarUserRole, sidebarAvatarInitials, setSidebarAvatarInitials } = useContext(SidebarContext);
    const { roles, setRoles } = useContext(RolesContext);
    const [localPerms, setLocalPerms] = useState([]);
    const permsData = useRef(null);
    const [hasPermData, setHasPermData] = useState(false);
 
    //if (accounts.length === 0) {
    //    instance.loginRedirect(loginRequest);
    //}
        if (inProgress === InteractionStatus.None && !isAuthenticated && instance.getAllAccounts.length === 0) {
            instance.loginRedirect(loginRequest).catch((error) => console.log(error));

         
            //instance.loginRedirect(loginRequest).then((response) => {
            //    console.log("login redirect called --- home.jsx 55", response.account);
            //    //instance.setActiveAccount(response.account);
                

               
            //}).catch((error) => console.log(error));

            if (instance.getAllAccounts.length > 0) {

                //setSidebarActiveUser(accounts[0].idTokenClaims);
                setSidebarActiveUser(instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn));

                
            }

    }

    //useEffect(() => {

    //    instance.loginRedirect(loginRequest).then((response) => {
    //        console.log("login redirect called --- home.jsx 55", response.account);
    //        instance.setActiveAccount(response.account);



    //    }).catch((error) => console.log(error));

    //    if (instance.getActiveAccount() !== null) {
    //        //call authorization
    //        console.log("active account found");
            
    //        setCallAuth(true);
    //    }
    //    console.log("instance changed, activeAccount ---- ", instance.getActiveAccount());
    //}, [instance]);


    useEffect(() => {
     
        const authenticateUser = async () => {
            if (accounts.length > 0) {
                //console.log("account stuff" + accounts[0].idTokenClaims);
                //console.log("account stuff" + accounts[0].idTokenClaims);
                //setSidebarActiveUser(accounts[0].idTokenClaims);
                //do authorization check

                //const account = accounts[0]?.idTokenClaims;
                //const account = instance.getActiveAccount()?.idTokenClaims;
                const account = instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn)?.idTokenClaims;
                console.log("current account picked - ", account);
                console.log("api call start - acquire token")
                /*await instance.acquireTokenSilent({ loginRequest, account: accounts[0] })*/
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        console.log("acquire token result - ", result);
                        //let accessToken = result.accessToken;
                        console.log("result id token - " + result.idToken);
                        console.log("account sub - ", account?.sub);
                        //call authorization method
                        const fetchData = async () => {
                            await fetch(process.env.REACT_APP_BACK_URL + '/api/Auth/Authorize', {
                                method: 'GET',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result?.idToken,
                                },
                               
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    console.log("init authorization call response - ", response)
                                    setRoles(response?.userRole?.roleId)
                                    setSidebarUserRole(response?.userRole?.roleId)
                                    console.log("auditIds - ", response?.auditIds?.auditIds[0]?.auditId)
                                    console.log("auditName - ", response?.auditIds?.auditIds[0]?.auditName)
                                    console.log("isClient? - ", response?.isClient)
                          
                                    permsData.current = response;


                                    response?.auditIds?.auditIds?.map((audit) => {
                                        setLocalPerms(localPerms => [...localPerms,
                                            {
                                                isClient: response?.isClient,
                                                auditId: audit?.auditId,
                                                auditName: audit?.auditName
                                            }

                                        ])
                                    });

                                        
                                    setHasPermData(true);

                                    if (response?.auditIds?.auditIds?.length > 1) {
                                        //change route
                                        setClientRoute(true);

                                    }

                                        //setPermissions([
                                        //    {
                                        //        isClient: response?.isClient,
                                        //        auditId: response?.auditIds?.auditIds[0]?.auditId,
                                        //        auditName: response?.auditIds?.auditIds[0]?.auditName

                                        //    }
                                        //]);

                                        //setLocalPerms([
                                        //    {
                                        //        isClient: response?.isClient,
                                        //        auditId: response?.auditIds?.auditIds[0]?.auditId,
                                        //        auditName: response?.auditIds?.auditIds[0]?.auditName

                                        //    }
                                        //]);

                                    setSidebarAvatarInitials(account?.given_name?.slice(0, 1) + account?.family_name?.slice(0, 1));                                



                                    console.log(permissions)
                                    //console.log(localPerms)

                                }).catch((error) => {
                                    console.log(error)
                                })


                        }
                        fetchData();
                    }).catch((error) => {
                        console.log(error)
                        if (error instanceof InteractionRequiredAuthError) {
                            console.log("error redirect --- home 176");
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });

            }
        }
        authenticateUser();


    }, [accounts, setHasPermData]);


    const [clientRoute, setClientRoute] = useState(false); //direct client based on active audits


    useEffect(() => {

        setPermissions(localPerms);

    }, [hasPermData]);

    useEffect(() => {

        if (permissions.length > 1) {
            //change route
            setClientRoute(true);
          
        }

    }, [permissions, setPermissions]);

    useEffect(() => {
        console.log("localPerms ==== ", localPerms)
        console.log("perms --- ", permissions)
        console.log("client route ---- ", clientRoute)

    }, [clientRoute]);
    return (
        <>
            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', xl: 'block' } }} >

                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 4, ml: 0, mr: 0, display: { xs: 'none', md: 'block' } }}>


                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                        <Skeleton height={30} width={180} />
                    </SkeletonTheme>

                </Container >


                <Container sx={{ ml: 0, mr: 0 }}>
                    <Grid container spacing={3} sx={{ mt: 1, mb: 5, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>

                        <Grid item xs={4}>
                            <div className="row-no-left-margin">
                                <div className="center">
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={100} width={300} />
                                    </SkeletonTheme>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="row-no-left-margin">
                                <div className="center">
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={100} width={300} />
                                    </SkeletonTheme>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="row-no-left-margin">
                                <div className="center">
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={100} width={300} />
                                    </SkeletonTheme>
                                </div>
                            </div>
                        </Grid>




                    </Grid>
                </Container>

                <Container sx={{ ml: 0, mr: 0 }}>
                    <Grid container spacing={1} sx={{ mt: 1, mb: 0.5, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                        <Grid item xs={12}>

                            <div className="row-no-left-margin">
                                <div className="center">
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={360} width={'70VW'} />
                                    </SkeletonTheme>
                                </div>
                            </div>


                        </Grid>
                    </Grid>
                </Container>

                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>

                {hasPermData ?
                    (isAuthenticated ? (

                       

                        permsData?.current?.isClient ? (
                        //ADD third nested check if client's audit list is greater than 1
                            clientRoute ?
                                (
                                    <Navigate to="/report" replace />

                                ) : (

                                    <Navigate to={`/report/${String(permsData?.current?.auditIds?.auditIds[0]?.auditId)}/${permsData?.current?.auditIds?.auditIds[0]?.auditName}`} replace />
                                )

                        ) : (
                            <Navigate to="/report" replace />
                        )

                    ) : (

                        <>

                        </>

                    )) : (<></>)}


            </Box>

            {/* //START MOBILE */}

            <Box component="main" sx={{ width: '100%', height: '100VH', bgcolor: '#FAFAFA', flexGrow: 1, paddingTop: 1, mt: 4.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/*  MOBILE PAGE COMPONENTS */}

                {/*  MOBILE PAGE TITLES/HEADINGS*/}

                <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>


                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                        <Skeleton height={30} width={180} />
                    </SkeletonTheme>

                </Container >

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {/* Project Cards - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 2.55, mr: 0, display: { xs: 'block', md: 'none' } }}>

                        <Grid item xs={4}>
                            <div className="row-no-left-margin">
                                <div className="center">
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={100} width={'75VW'} />
                                    </SkeletonTheme>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="row-no-left-margin">
                                <div className="center">
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={100} width={'75VW'} />
                                    </SkeletonTheme>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="row-no-left-margin">
                                <div className="center">
                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                        <Skeleton height={100} width={'75VW'} />
                                    </SkeletonTheme>
                                </div>
                            </div>
                        </Grid>


                    </Grid>
                </Container>

                {/* MOBILE TABLE*/}

                <Grid container spacing={1} sx={{ mt: 1, mb: 2.5, ml: 0.75, mr: 10, display: { xs: 'block', md: 'none', xl: 'none' } }}>
                    <Grid item xs={12}>

                        <div className="row-no-left-margin">

                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                <Skeleton height={360} width={'75VW'} />
                            </SkeletonTheme>


                        </div>


                    </Grid>
                </Grid>

                {hasPermData ?
                    (isAuthenticated ? (

                        permsData?.current?.isClient ? (
                            //ADD third nested check if client's audit list is greater than 1
                            clientRoute ?
                            (
                                <Navigate to="/report" replace />

                            ) : (

                                <Navigate to = {`/report/${String(permsData?.current?.auditIds?.auditIds[0]?.auditId)}/${permsData?.current?.auditIds?.auditIds[0]?.auditName}`} replace />
                            )
                           
                        ) : (
                                <Navigate to="/report" replace />
                        )

                    ) : (

                        <>

                        </>

                    )) : (<></>)}

            </Box>
        </>
    );

}

export default Home



//import React, { Component } from 'react';
//import { useParams, useNavigate, Navigate } from "react-router-dom";
//import { Theme, Button } from 'react-daisyui';
//import { useIsAuthenticated, useMsal } from "@azure/msal-react";
//import { EventType, InteractionType, InteractionStatus } from "@azure/msal-browser";
//import { b2cPolicies, loginRequest, protectedResources } from "../authConfig";

//function Home() {
//    let navigate = useNavigate()
//    const isAuthenticated = useIsAuthenticated();
//    const { instance, accounts, inProgress } = useMsal();

//    if (inProgress === InteractionStatus.None && !isAuthenticated) {
//        instance.loginRedirect(loginRequest);
//    }

//    return (
//        <div>
//          {isAuthenticated ? (
//              <Navigate to="/report" replace />
//          ) : (
//           <h3>Home (Logged Out)</h3>
//          )}

//      </div>
//  );

//}

//export default Home
