import React, { useState, useEffect, useCallback } from 'react';
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';


function useFetch() {

    const { instance, accounts, inProgress } = useMsal();
    const [responseData, setResponseData] = useState(null); //data return from api call
    const [loading, setLoading] = useState(null);
    const [responseError, setResponseError] = useState(null); //returns error message

    //retrieve id token from b2c
    async function getData(address, options, data){
        setLoading(true);
        setResponseData(null);
        setResponseError(null);
        //retrieve access token from b2c
        
        if (accounts.length > 0) { //if account exists authorize user
            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {
                    console.log(result);
                    //api call if token is valid
                    const fetchData = async () => {

                        if (data !== null) { //data provided
                            await fetch(address, {
                                method: options,
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },

                                body: JSON.stringify(data)
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    setResponseData(response);
                                    setLoading(false);
                                }).catch((error) => {
                                    console.log(error)
                                    setResponseError(error);
                                    setLoading(false);
                                })
                        } else { //no data provided
                            await fetch(address, {
                                method: options,
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    setResponseData(response);
                                    setLoading(false);
                                }).catch((error) => {
                                    console.log(error)
                                    setResponseError(error);
                                    setLoading(false);
                                })
                        }


                    }

                    fetchData();



                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }
                    setLoading(false);
                });
        }
    };



    //useEffect(() => {

    //    if (accounts.length > 0) { //if account exists authorize user
    //        getData();
    //    }

    //}, [getData]);

    return { responseData, responseError, loading, getData }; //return data and error
}
export default useFetch;

