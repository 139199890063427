//admin layout if user is logged in and has role of admin
import React, { useState, createContext, useMemo, useContext } from 'react';
import { Link, Navigate, useOutlet } from "react-router-dom";
//import NavigationBar from './NavigationBar';

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { BsBoxArrowLeft, BsFillBellFill, BsFillBarChartFill, BsCheck2Square, BsPersonFill, BsPeople, BsGearFill, BsGear, BsHouse, BsHouseFill, BsCardChecklist } from "react-icons/bs";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { HiHome } from "react-icons/hi";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext } from "../App";
import Sidebar from "../layouts/Sidebar";
import SidebarMobile from "../layouts/SidebarMobile";



export default function PageLayout({ children }) {

    /*    const { user, logout } = useAuth();*/
    const outlet = useOutlet();

    const isAuthenticated = useIsAuthenticated();
    //console.log("pagelayout user isAuthenticated:" + isAuthenticated)

    const NavigationBar = React.lazy(() => import('./navigation/NavigationBar'));

  

    const {newRoutes, setRoutes} = useContext(RoutesContext);
    



   
    return (
        <>

           

            {/* M-EDIT - Add Width and Height properties to cover page container, THIS is where we can properly set Desktop backgrounds */}

            <Box maxHeight={false} sx={{ bgcolor: '#FAFAFA', mt: 4, height: '100VH', width: '100VW', display: { xs: "none", md: "flex", xl: "flex" } }}>

               {/* <React.Suspense fallback={<Skeleton width={30} height={10} count={6} />}>*/}
                    {isAuthenticated ? (
                       
                        <>
                            <Sidebar routes={
                                newRoutes
                               
                            }
                                accountRoutes={[
                                   

                                ]}
                            />
                        </>
                    ) : (
                       
                        <>
                            <Sidebar routes={[
                                //{ label: "Home", path: "/", icon: <HiHome /> },
                                //{
                                //    label: "Dashboard", path: "report", icon: <BsFillBarChartFill />
                                //},
                                
                            ]}
                                accountRoutes={[
                                   
                                    ]}
             

                            />
                        </>
                    )}

                    {outlet}

         



              

                   
                    {children}

               
            </Box>



            <Container sx={{ display: { xs: "block", md: "none" }, backgroundColor: '#FAFAFA' }}>
                <div>

                  
                        {isAuthenticated ? (
                           
                            <>
                                <SidebarMobile routes={
                                    newRoutes
                                  
                                }

                                    accountRoutes={[
                                       
                                    ]}
                                
                                /> 
                            </>
                        ) : (
                           
                            <></>
                        )} {outlet}
                  
                    <Box bgcolor="red" sx={{ backgroundColor: '#F6F7F8;', mt: 10, ml: 2, display: 'flex', width: '100VW' }}>
                       

                        {children}
                     
                    </Box>

                </div>
            </Container>



        </>
    )

}

