import React, { useState, useContext } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import UserManagementModalUpdateUser from "../modals/UserManagementModalUpdateUser";
import { loginRequest, b2cPolicies } from '../../authConfig';
import { MdOutlineMoreHoriz } from "react-icons/md";
import { UserManagementContext } from '../../pages/Counter2';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';



function UserManagementTableMobile({ tableData, desiredWidth, desiredHeight }) {

    const { instance, accounts, inProgress } = useMsal();

    if (desiredWidth == undefined || desiredWidth == null) {

    }

    const {
        handleGatherDataAndOpenUpdateUserManagementModal
    } = useContext(UserManagementContext);

    //from mui documentation

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        if (name !== undefined) {
            
            return {
                sx: {
                    bgcolor: '#23549F',
                    color: '#FFFFFF',
                    fontSize: 12
                },
                children: `${name.split(' ')[0][0]}`,
            };
        }
    }

    //for Table Ellipsis Menu

    const [anchorEl, setAnchorEl] = useState(null);
    const openUserManagementMenu = Boolean(anchorEl);
    const handleOpenUserManagementMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseUserManagementMenu = () => {
        setAnchorEl(null);
    };

    function handleUpdateTableIDState(id, first_name, last_name, user_name, user_role, user_status, user_email, phone_number) {
      

        handleGatherDataAndOpenUpdateUserManagementModal(id, first_name, last_name, user_name, user_role, user_status, user_email, phone_number)
    }

    function handleStatusTableIDState(id, first_name, last_name, user_name, user_role, user_status, user_email, phone_number) {
        if (accounts.length > 0) {
            instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => { 

                    fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/UpdateUser', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + result.idToken,
                        },
                        body: JSON.stringify({ "currentEmail": user_email, "email": user_email, "givenName": first_name, "surname": last_name, "displayName": user_name, "phoneNumber": phone_number, "accountEnabled": !(user_status) }),
                    })
                        .then(res => res.json())
                        .then((response) => {
                           /* console.log(response)*/
                        })

                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }

                });
                
  
        }
      

    }

    function handleResendInviteTableIDState(user_email, first_name, last_name) {
        //fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/SendInvite', {//REACT_APP_BACK_URL
        //    method: 'POST',
        //    mode: 'cors',
        //    headers: { 'Content-Type': 'application/json' },
        //    body: JSON.stringify({ "email": user_email, "givenName": first_name, "surname": last_name }),
        //})
        //    /*    .then(res => res.json())*/
        //    .then((result) => {
        //        /*console.log(result)*/
        //    })
        if (accounts.length > 0) { //if account exists authorize user
            instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                .then(result => {
                    //console.log(result);
                    fetch(process.env.REACT_APP_INVITE_URL + '/api/userMgmt/SendInvite', {//REACT_APP_INVITE_URL
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + result.idToken,
                        },
                        body: JSON.stringify({ "email": user_email, "givenName": first_name, "surname": last_name }),
                    })
                        /* .then(res => res.json())*/
                        .then((response) => {
                            /* console.log(response)*/
                        })
                }).catch((error) => {
                    console.log(error) //token expired/is not valid => user is redirected to login page
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                    }

                });

        }
    }

    const rows = tableData;

    const columns = [
       
        {
            field: 'givenName', headerName: 'User', width: 200, renderCell: (cellValues) => {
                return (
                    <>
                        <div className="spacer-margin-right">
                            <Avatar sx={{ mr: '1rem' }}   {...stringAvatar(cellValues?.row?.givenName)} />
                        </div>
                        <div>
                            <Typography sx={{ color: '#2563EB', fontWeight: 700, fontSize: '16px', }}>{cellValues?.row?.givenName + " " + cellValues?.row?.surname}</Typography>
                            <Typography sx={{ color: '#737373', fontWeight: 600, fontSize: '14px', }}>{cellValues?.row?.email}</Typography>
                        </div>
                    </>


                );
            }
        },
       
        {
            field: 'accountEnabled', headerName: 'Status', width: 110, renderCell: (cellValues) => {
                return (
                    <>
                        {(cellValues.row.accountEnabled && cellValues.row.finishedRegistration) ? <div>
                            <Chip style={{ color: "#10B981", borderColor: "#10B981", fontWeight: '600' }} label={"Active"} size="small" variant="outlined" />
                        </div> : (cellValues.row.accountEnabled && !cellValues.row.finishedRegistration) ? <div>
                            <Chip style={{ color: "#EDC041", borderColor: "#EDC041", fontWeight: '600' }} label={"Invite Sent"} size="small" variant="outlined" />
                        </div> : <div>
                            <Chip style={{ color: "#ef4444", borderColor: "#ef4444", fontWeight: '600' }} label={"Disabled"} size="small" variant="outlined" />
                        </div>}
                    </>
                );
            }
        },
        {
            field: 'role', headerName: 'Role', width: 100, renderCell: (cellValues) => {
                return (
                    <div>
                        <Typography sx={{ color: '#737373', fontWeight: 400, fontSize: '14px' }} >Client</Typography>
                    </div>

                );
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: '...',
            width: 60,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    label={"Manage"}

                    onClick={() => handleUpdateTableIDState(cellValues.row.objectId, cellValues.row.givenName, cellValues.row.surname, cellValues.row.displayName, "CLIENT", cellValues.row.accountEnabled, cellValues.row.email, cellValues.row.phoneNumber)
                    }
              

                    showInMenu
                />,
                <GridActionsCellItem
                        label={"Resend Invite"}
                        sx={(cellValues.row.accountEnabled && !cellValues.row.finishedRegistration) ? { display: { xs: 'block', md: 'block', xl: 'block' } } : { display: { xs: 'none', md: 'none', xl: 'none' } }}
                        onClick={() => handleResendInviteTableIDState(cellValues.row.email, cellValues.row.givenName, cellValues.row.surname)
                        }
                      
                        showInMenu
                 />,
                <GridActionsCellItem
                    label={cellValues.row.accountEnabled ? "Disable" : "Enable"}
                    sx={cellValues.row.accountEnabled ? { color: '#ef4444' } : { color: "#10B981" }}
                    onClick={() => handleStatusTableIDState(cellValues.row.objectId, cellValues.row.givenName, cellValues.row.surname, cellValues.row.displayName, "CLIENT", cellValues.row.accountEnabled, cellValues.row.email, cellValues.row.phoneNumber)}
                    showInMenu
                />,
               
              
                
              
            ],
        },
       

    ];

    return (
        <>
            {desiredWidth ? (<>
                <div style={{ minHeight: desiredHeight ? (desiredHeight) : ('10VH'), height: '5vh', width: desiredWidth }}>
                    <DataGrid
                      
                        disableSelectionOnClick={true}
                        disableMultipleRowSelection={true}
                        getRowId={(row) => row.objectId}
                        rows={rows}
                        columns={columns}
                        sx={{
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold !important',
                                overflow: 'visible !important'
                            }
                        }}
                    />
                </div>
            </>) : (<>
                <div style={{ minHeight: desiredHeight ? (desiredHeight) : ('10VH'), height: '5vh', width: "100%" }}>
                        <DataGrid
                           
                            disableSelectionOnClick={true}
                            disableMultipleRowSelection={true}
                            getRowId={(row) => row.objectId}
                            rows={rows}
                            columns={columns}
                            sx={{
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                                '.MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold !important',
                                    overflow: 'visible !important'
                                }
                            }}
                    />
                </div>
            </>)}
        </>
    );
}

export default UserManagementTableMobile;