import React, { useEffect, useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
//import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Nav } from 'reactstrap';
import bmilogo from '../../assets/bmi-logo-cross-transparent.png';
//import { useAuth } from "./auth/Auth";
import { loginRequest, b2cPolicies } from "../../authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import './NavMenu.css';
/*import { Navbar, Button, Dropdown, Drawer } from 'react-daisyui';*/
import { BsBoxArrowLeft } from "react-icons/bs";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';



export default function NavigationBar({ routes }) {
    //const navigate = useNavigate();
    //const { user, logout } = useAuth();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [visible, setVisible] = useState(false);
    const [isActive, setActive] = useState(false);
    //let activeAccount;
    //if (instance) {
    //    activeAccount = instance.getActiveAccount;
    //}



    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error))
        console.log(isAuthenticated);
    }

    const handleLogout = () => {
        instance.logoutRedirect()
    }

    //update active page on sidebar
    //const setCurrent = (index) => {
    //    const newRoutes = [...routes];
    //    newRoutes[index].active = true;
    //    setRoutes[newRoutes];
    //}

    return (
        <></>
    );

}


//import React, { useEffect, useState } from "react";
//import { useNavigate, Link, NavLink } from "react-router-dom";
////import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Nav } from 'reactstrap';
//import bmilogo from '../../assets/bmi-logo-cross-transparent.png';
////import { useAuth } from "./auth/Auth";
//import { loginRequest, b2cPolicies } from "../../authConfig";
//import { useIsAuthenticated, useMsal } from "@azure/msal-react";
//import './NavMenu.css';
//import { Navbar, Button, Dropdown, Drawer } from 'react-daisyui';
//import { BsBoxArrowLeft } from "react-icons/bs";
//import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css';



//export default function NavigationBar({ routes }){
//    //const navigate = useNavigate();
//    //const { user, logout } = useAuth();
//    const isAuthenticated = useIsAuthenticated();
//    const { instance } = useMsal();
//    const [visible, setVisible] = useState(false);
//    const [isActive, setActive] = useState(false);
//    //let activeAccount;
//    //if (instance) {
//    //    activeAccount = instance.getActiveAccount;
//    //}



//    const handleLogin = () => {
//        instance.loginRedirect(loginRequest).catch((error) => console.log(error))
//        console.log(isAuthenticated);
//    }

//    const handleLogout = () => {
//        instance.logoutRedirect()
//    }

//    //update active page on sidebar
//    //const setCurrent = (index) => {
//    //    const newRoutes = [...routes];
//    //    newRoutes[index].active = true;
//    //    setRoutes[newRoutes];
//    //}

//  return (
//      <div className="flex flex-nowrap font-sans">
//          {isAuthenticated ? (
//            <div className="bg-slate-200 box border-r-lg border-neutral">
//                 <Button className="btn btn-circle btn-ghost m-5 justify-items-center ">
//                    <img src={bmilogo} alt="BMI" style={{ height: 40, width: 40 }}/>
//                </Button> 
//                <ul className=" flex flex-col overflow-y-auto w-60 p-6 pt-16 place-items-start min-w-max">
//                      {routes?.map((route, index) => (
//                          <Button key={index} color="ghost" className="space-x-4 inline-block cursor-pointer rounded-md">
//                              <NavLink tag={Link} to={route.path} className="text-lg text-inherit flex flex-row items-center space-x-2">
//                                <div>{route.icon}</div>
//                                <div>{route.label}</div>
//                            </NavLink>
//                        </Button>
//                    ))}
//                      <Button key={"logout"} onClick={handleLogout} color="ghost" className="inline-block cursor-pointer rounded-md">
//                        <NavLink tag={Link} to={"/"} className="text-lg text-inherit  flex flex-row items-center space-x-2">
//                            <div>{<BsBoxArrowLeft />}</div>
//                            <div>Logout</div>
//                        </NavLink>
//                    </Button>
//                </ul>    
//            </div>
//          ) : (
//          <Navbar className="bg-base-100 shadow-xl rounded-box">
//              <Navbar.Start>
//                <Button color="ghost" tabIndex={0} className="btn btn-circle btn-ghost">
//                    <img src={bmilogo} alt="BMI" style={{ height: 40, width: 40 }} /> 
//                </Button>
//              </Navbar.Start>
//              <Navbar.End>
//                  <Dropdown className="dropdown dropdown-end">
//                      <Button color="ghost" shape="circle" tabIndex={0} className="btn">
//                          <svg
//                              xmlns="http://www.w3.org/2000/svg"
//                              className="h-5 w-5"
//                              fill="none"
//                              viewBox="0 0 24 24"
//                              stroke="currentColor"
//                          >
//                              <path
//                                  strokeLinecap="round"
//                                  strokeLinejoin="round"
//                                  strokeWidth={2}
//                                  d="M4 6h16M4 12h16M4 18h7"
//                              />
//                          </svg>
//                      </Button>
//                      <Dropdown.Menu tabIndex={0} className="menu-compact w-52">
//                              <ul>
//                              {routes?.map((route, index) => (
//                                  <Dropdown.Item key={index}>
//                                          <NavLink tag={Link} to={route.path} className="text-lg text-inherit">{route.label}</NavLink>
//                                  </Dropdown.Item>
//                              ))}
//                                <Dropdown.Item key={"login"} onClick={handleLogin}>
//                                    <NavLink tag={Link} to={"/"} className="text-lg text-inherit">Login</NavLink>
//                                </Dropdown.Item>
//                              </ul>
//                      </Dropdown.Menu>
//                  </Dropdown>
//              </Navbar.End>
//          </Navbar>
//          )}   
//      </div>
//  );
   
//}

////if authenticated ->
//    //if client -> access to this nav (project scoped)
//    //else
//    //normal navbar and dashboard